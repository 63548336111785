import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Underline from '../../uielements/Underline'
import Spacer from '../../uielements/Spacer'
import { useTranslation } from 'react-i18next'
import MainTitle from '../../uielements/MainTitle'

const ReadMoreLink = styled.div`
    margin: 5px 0;

    a {
        color: ${({ theme }) => theme.blue};
        text-decoration: none;
        margin: 10px 0;
        display: block;

        &:hover {
            color: ${({ theme }) => theme.purple};
        }
    }
`

const ReadMore = props => {
    const { t } = useTranslation()
    return (
        props.readMore && (
            <section>
                <Spacer left="1" right="1" md={{ left: '0.2', right: '1' }} />
                <MainTitle>{t('read-more-capital')}</MainTitle>
                <Spacer left="1" right="1" md={{ left: '0.2', right: '1' }} />

                {props.readMore.map(post => {
                    return (
                        <ReadMoreLink key={post.slug}>
                            <Link
                                title={post.title}
                                to={`/${post.postType}/${post.slug}`}
                                key={post.slug}
                            >
                                {post.title}
                            </Link>
                            <Underline w="80px" />
                        </ReadMoreLink>
                    )
                })}
            </section>
        )
    )
}

ReadMore.defaultProps = {
    moreTitle: 'Read More',
}

export default ReadMore
