import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ obj }) => {
    const imageUrl = giveMeTheImage(obj)

    return (
        imageUrl && (
            <picture>
                {/*<source media="(min-width: 800px)" srcSet="/path/to/image_800x600.webp"/>*/}
                {/*<source media="(min-width: 400px)" srcSet="/path/to/image_400x300.webp"/>*/}
                <img
                    src={imageUrl}
                    srcSet={giveMeTheSrcSet(obj)}
                    sizes={giveMeTheSizes(obj)}
                    alt={giveMeTheAlt(obj)}
                />
            </picture>
        )
    )
}

Image.propTypes = {
    obj: PropTypes.object,
    // src: PropTypes.string,
    // alt: PropTypes.string
}

Image.defaultProps = {
    // src: "http://placehold.it/500x500",
    // alt: "default Image"
}

const giveMeTheImage = obj => {
    if (obj) {
        return obj.url
    }

    return null
}

const giveMeTheSrcSet = obj => {
    if (obj) {
        return obj.gatsbyImageData.images.sources[0].srcSet
    }

    return null
}

const giveMeTheSizes = obj => {
    if (obj) {
        return obj.gatsbyImageData.images.sources[0].sizes
    }

    return null
}

const giveMeTheAlt = obj => {
    if (obj) {
        return obj.title
    }

    return null
}

export default Image
