import styled from 'styled-components'
import { screenSizes } from '../../../lib/constants'

const Columns = styled.div`
    columns: 2;
    column-gap: 30px;

    @media (max-width: ${screenSizes.md}px) {
        columns: 1;
    }
`

export default Columns
